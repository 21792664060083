
import { LoginCallback } from '@okta/okta-react'
import { AuthRole } from 'auth/const'
import ProtectedRoute from 'auth/ProtectedRoute'
import {
  RasnDetails,
  InternalServerError,
  Unauthorized,
  GoodsHolderOverview,
  RasnOverview,
  GoodsHolderDetails,
  Secret
} from 'pages'
import { Routes as ReactRoutes, Route } from 'react-router-dom'

export const Routes = () => (
  <ReactRoutes>
    <Route path='/login/callback' element={<LoginCallback />} />
    <Route path='/ise' element={
      <InternalServerError />
    } />
    <Route path='/unauthorized' element={
      <Unauthorized />
    } />
    <Route element={<ProtectedRoute role={AuthRole.REPORT_BASIC} />}>
      <Route path={'/'} element={<RasnOverview />} />
      <Route path={'/rasn/:rasnId'} element={<RasnDetails />} />
      <Route path={'/goodsholder'} element={<GoodsHolderOverview />} />
      <Route path={'/goodsholder/:goodsHolderId'} element={<GoodsHolderDetails />} />
      <Route path={'/secret'} element={<Secret />} />
    </Route>
  </ReactRoutes>
)
