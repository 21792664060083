import React, { useState, useEffect, useRef } from 'react'
import './TristateCheckbox.scss'

interface TristateCheckboxProps {
  checked: string
  setChecked: (checked: string) => void
}

/** Checkbox with 3 states: true, false or indeterminate.
 * Component is in indeterminate state when the `checked` value is ''
 *
 * CSS rendering based on https://codepen.io/bradyhullopeter/pen/bGrjzJy
**/
export const TristateCheckbox = ({ checked, setChecked }: TristateCheckboxProps) => {
  const [booleanValue, setBooleanValue] = useState(checked)
  const checkboxRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    setBooleanValue(checked)
  }, [checked])

  useEffect(() => {
    if (checkboxRef.current) {
      checkboxRef.current.indeterminate = booleanValue === ''
    }
  }, [booleanValue])

  const handleBooleanChange = () => {
    setBooleanValue((prevState) => {
      let newValue = ''
      switch (prevState) {
        case '':
          newValue = 'true'
          break
        case 'true':
          newValue = 'false'
          break
        case 'false':
          newValue = ''
          break
      }
      // Perform async state update so that this and parent component can update separately
      // Throws exception otherwise
      setTimeout(() => { setChecked(newValue) }, 0)
      return newValue
    })
  }

  return (
        <label className="tristate-checkbox"
               onClick={(e) => { e.stopPropagation() }}>
            <input
                type="checkbox"
                ref={checkboxRef}
                checked={booleanValue === 'true'}
                onChange={handleBooleanChange}
                className={booleanValue === '' ? 'indeterminate' : ''}
            />
            <div>
                <svg className="tristate-check" viewBox="-2 -2 35 35" aria-hidden="true">
                    <title>checkmark-circle</title>
                    <polyline points="7.57 15.87 12.62 21.07 23.43 9.93" />
                </svg>
            </div>
        </label>
  )
}
