import { Button, Modal, Table, TableCell } from '@nike/eds'
import { useGetAppInfoQuery } from 'api/app-info'
import { type ApplicationDetail, type FlyWayMigrationInfo } from 'types'

export const AppInfoModal = ({ setShowAppInfo }: AppInfoModalProps) => {
  const { data, isError, isUninitialized, isLoading } = useGetAppInfoQuery()
  const flywayHeaders = ['Version', 'Description', 'Type', 'Timestamp']
  const applicationHeaders = ['Name', 'Version', 'Timestamp']

  const modalHeader = (
    <div>Stack information</div>
  )

  const closeModal = () => {
    setShowAppInfo(false)
  }

  const modalFooter = (
    <div className={'mb-4'}>
      <Button onClick={closeModal}>Close</Button>
    </div>
  )

  const renderHeader = (type: string, headers: string[]) => {
    return <thead>
    { headers.map((header: string) => {
      return <TableCell key={`header-${type}-${header}`}>{ header }</TableCell>
    }) }
    </thead>
  }

  const renderApplicationDetail = (applicationDetail: ApplicationDetail, identifier: string) => {
    return <tr key={`div_${identifier}_info`}>
      <TableCell>{applicationDetail.name}</TableCell>
      <TableCell>{applicationDetail.version !== '' ? `${applicationDetail.version}` : 'Unknown'}</TableCell>
      <TableCell>{applicationDetail.timestamp !== '' ? `${applicationDetail.timestamp}` : 'Unknown'}</TableCell>
    </tr>
  }
  const renderFlywayInfo = (m: FlyWayMigrationInfo, identifier: string) => {
    return <tr key={`div_${identifier}_info`}>
      <TableCell>{m.version}</TableCell>
      <TableCell>{m.description}</TableCell>
      <TableCell>{m.type}</TableCell>
      <TableCell>{m.timestamp}</TableCell>
    </tr>
  }
  return (
    <>
      <Modal className={'hidden'} isOpen={true} onDismiss={closeModal} headerSlot={modalHeader}
             footerSlot={modalFooter}
             hideFade={true}>
      {isError && <>Failed to load application info</>}
      {isLoading && <>Loading application info</>}
      {(data != null && !isLoading && !isUninitialized && !isError) &&
          <>
              <p className="table-header">Containers</p>
              <Table id='containers_table' className='w-full mb-2'>
                {renderHeader('container', applicationHeaders)}
                {data?.containers?.map((container: ApplicationDetail, index: number) => {
                  return renderApplicationDetail(container, `container_${index}`)
                })}
              </Table>
              <p className="table-header">UIs</p>
              <Table id='uis_table' className='w-full mb-2'>
                {renderHeader('ui', applicationHeaders)}
                {data?.uis?.map((ui: ApplicationDetail, index: number) => {
                  return renderApplicationDetail(ui, `ui_${index}`)
                })}
              </Table>
              <p className="table-header">Lambdas</p>
              <Table id='lambdas_table' className='w-full mb-2'>
                {renderHeader('lambda', applicationHeaders)}
                {data?.lambdas?.map((lambda: ApplicationDetail, index: number) => {
                  return renderApplicationDetail(lambda, `lambda_${index}`)
                })}
              </Table>
              <p className="table-header">Flyway migrations</p>
              <Table id='flyway_migration_table' className='w-full mb-2'>
                {renderHeader('flyway', flywayHeaders)}
                {data?.flywayInfo?.migrations?.map((flywayMigrationInfo: FlyWayMigrationInfo, index: number) => {
                  return renderFlywayInfo(flywayMigrationInfo, `flyway_migration_${index}`)
                })}
              </Table>
          </>
      }
      </Modal>
    </>
  )
}

interface AppInfoModalProps {
  setShowAppInfo: (showAppInfo: boolean) => void
}
