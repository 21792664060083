import { useGetFlagQuery } from 'api/feature-toggle'
import { useCloseRasnMutation, useSearchRasnsQuery, useUnlockRasnMutation } from 'api/rasn'
import { CloseRasnIcon } from 'components/icon'
import { type ConfirmationConfig, TableView } from 'components/tableview'
import { ColumnType } from 'components/tableview/Filter'
import { usePaginatedData } from 'components/tableview/usePaginatedData'
import { useTableState } from 'components/tableview/useTableState'
import { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { type QueryResult, type Rasn, RasnChannel, RasnStatus } from 'types'
import { renderExpectedQuantityInCell } from 'utils/expectedQuantityRendering'
import { getEnumValues } from 'utils/utils'

const FILTER_INPUT_DELAY_MS = 500

export const RasnOverview = () => {
  const tableState = useTableState()
  const data = usePaginatedData<QueryResult<Rasn>>(useSearchRasnsQuery, tableState.pagination, tableState.columnFilters, tableState.sorting)
  const navigate = useNavigate()
  const [unlockRasn] = useUnlockRasnMutation()
  const [closeRasn] = useCloseRasnMutation()

  const { data: canUseRasnBookkeeping } = useGetFlagQuery('rasn_bookkeeping')

  useEffect(() => {
    localStorage.setItem('rasn_bookkeeping', canUseRasnBookkeeping ? canUseRasnBookkeeping.toString() : 'false')
  }, [canUseRasnBookkeeping])

  const columns = useMemo(() => [
    { id: 'number', header: 'RASN', type: ColumnType.TEXT, columnWidth: 8 },
    { id: 'channel', header: 'Channel', type: ColumnType.ENUM, enumValues: getEnumValues(RasnChannel), columnWidth: 8 },
    { id: 'customerName', header: 'Customer Name', type: ColumnType.TEXT, columnWidth: 40 },
    { id: 'status', header: 'Status', type: ColumnType.ENUM, enumValues: getEnumValues(RasnStatus), columnWidth: 8 },
    { id: 'holdCode', header: 'Hold Code', type: ColumnType.TEXT, columnWidth: 8 },
    { id: 'totalExpectedUnits', header: 'Expected', type: ColumnType.NUMBER, columnWidth: 6, renderFn: renderExpectedQuantityInCell },
    { id: 'totalHandledUnits', header: 'Handled', type: ColumnType.NUMBER, columnWidth: 6 }
  ], [])

  const defaultActionsWithConfirmation = [
    {
      title: 'Unlock RASN',
      message: 'Are you sure you want to unlock this RASN?',
      icon: 'Unlock',
      confirmText: 'Unlock',
      show: (row: Rasn) => !!row.holdCode,
      onClick: (row: Rasn) => { unlockRasn(row.number) }
    }
  ]

  const [actionsWithConfirmation, setActionsWithConfirmation] = useState<ConfirmationConfig[]>(defaultActionsWithConfirmation)

  useEffect(() => {
    if (canUseRasnBookkeeping) {
      setActionsWithConfirmation([...defaultActionsWithConfirmation,
        {
          title: 'Close RASN',
          message: 'Are you sure you want to close this RASN?',
          icon: CloseRasnIcon,
          confirmText: 'Close',
          show: (row: Rasn) => row.status !== RasnStatus.CLOSED,
          onClick: (row: Rasn) => { closeRasn(row.number) }
        }
      ])
    }
  }, [canUseRasnBookkeeping])

  return (
    <TableView
      columns={columns}
      data={data?.results ?? []}
      rowCount={data?.totalCount ?? 0}
      tableState={tableState}
      textFilterDelay={FILTER_INPUT_DELAY_MS}
      actions={[
        {
          label: 'View',
          icon: 'Show',
          onClick: (row: Rasn) => { navigate(`/rasn/${row.number}`) }
        }
      ]}
      actionsWithConfirmation={actionsWithConfirmation}
    />
  )
}
