import { createApi } from '@reduxjs/toolkit/query/react'

import { customBaseQuery } from './CustomBaseQuery'

export const mainAdminSplitApi = createApi({
  reducerPath: 'mainAdminSplitApi',
  tagTypes: ['RasnDetails', 'SearchRasns', 'SearchGoodsHolders'],
  baseQuery: customBaseQuery({
    baseUrl: process.env.REACT_APP_ADMIN_API_URL
  }),
  endpoints: (builder) => ({})
})

export const mainApiSplitApi = createApi({
  reducerPath: 'mainApiSplitApi',
  tagTypes: [],
  baseQuery: customBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL
  }),
  endpoints: (builder) => ({})
})
