import { Button, Select, TextArea } from '@nike/eds'
import { useRetriggerUnitEventsMutation } from 'api/unit'
import { useState } from 'react'
import { showSnackbar } from 'redux/actions/snackbar.action'
import { dispatch } from 'redux/store'

export const Secret = () => {
  const [selectedType, setSelectedType] = useState<string>('SCANNED')
  const [unitIds, setUnitIds] = useState<string>('')
  const [resultProcessed, setResultProcessed] = useState<number>(-1)
  const [resultMissing, setResultMissing] = useState<string[]>([])
  const [retrigger] = useRetriggerUnitEventsMutation()

  const eventOptions = [
    { value: 'SCANNED', label: 'SCANNED' },
    { value: 'FINISHED', label: 'FINISHED' }
  ]

  const handleClick = async () => {
    try {
      const res = await retrigger({
        type: selectedType,
        unitIds: unitIds.split(',').map(id => id.trim())
      }).unwrap()
      console.log('result', res)
      setResultMissing(res.missingIds)
      setResultProcessed(res.processed)
      dispatch(showSnackbar('Successfully retriggered, see results below', 'success'))
    } catch (err) {
      console.log('caught error', err)
    }
  }

  return (
      <div className={'m-16'}>
        <div className={'eds-type--display-1 mb-4'}>Retrigger</div>
        <Select
            id="drop-it"
            options={eventOptions}
            defaultValue={eventOptions[0]}
            label="Type of unit event"
            onChange={v => {
              setSelectedType(v!.value)
            }}
            className={'mb-4'}
        />
        <TextArea
            className={'my-4'}
            id='id-textarea'
            label={'Unit ID\'s comma separated, CRLF & spaces get trimmed'}
            rows={5}
            resize={'vertical'}
            onChange={e => {
              setUnitIds(e.target.value)
            }}/>
        <Button
            onClick={() => {
              void handleClick()
            }}
        >Live, Laugh, LEvents</Button>
        <br/>
        {resultProcessed >= 0 && (
            <>
              <hr className={'my-8'}/>
              <div className={'eds-type--display-2 my-4'}>Results</div>
              <div className={'eds-h1 mt-4'}>{resultProcessed} processed{resultMissing.length > 0 && ', missing unit IDs: ' + resultMissing.join(', ')}</div>
            </>
        )}
      </div>
  )
}
