import { type InternalAxiosRequestConfig } from 'axios/index'

export function loadToken (): string | null {
  const storedJson = localStorage.getItem('okta-token-storage')
  const storedObject = storedJson != null ? JSON.parse(storedJson) : null
  if (storedObject?.accessToken?.accessToken != null && storedObject?.accessToken?.accessToken !== '') {
    return storedObject?.accessToken?.accessToken
  }
  return null
}

export function addTokenToBackendCalls (request: InternalAxiosRequestConfig<any>, token: string | null): void {
  if (token != null && isDataRequestFromBackend(request)) {
    request.headers.Authorization = `Bearer ${token}`
  }
}

function isDataRequestFromBackend (request: InternalAxiosRequestConfig<any>): boolean {
  const backend = process.env.REACT_APP_ADMIN_API_URL
  return backend != null &&
        (request.baseURL != null && request?.baseURL?.includes(backend)) &&
        (request.url != null && request?.url.startsWith('/'))
}
