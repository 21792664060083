import { Button } from '@nike/eds'
import { useNavigate } from 'react-router-dom'

export const InternalServerError = () => {
  const navigate = useNavigate()

  return (
    <>
      <div className="md:w-1/2 w-full m-auto mt-2">
        <div className="flex items-center place-content-between mb-4">
          <h1 className="pt-5 eds-type--title-1">
            500 Internal Server Error
          </h1>
        </div>
        <div style={{ marginTop: '2rem', height: 'fit-content' }} className="w-full bg-white eds-elevation--2">
          <div className="p-4">
            <>
              <p className={'text-lg mb-6 whitespace-pre-wrap'}>
                Oops, something went wrong. <br/>
                Try logging out and in again, if this issue persists please contact your local support team.
              </p>
              <Button onClick={() => { navigate('/') }} >Back to homepage</Button>
            </>
          </div>
        </div>
      </div>
    </>
  )
}
