import { mainAdminSplitApi } from 'redux/store/mainSplitApi'

const unitApi = mainAdminSplitApi.injectEndpoints({
  endpoints: (builder) => ({
    retriggerUnitEvents: builder.mutation<{ missingIds: string[], processed: number }, { type: string, unitIds: string[] }>({
      query: ({ type, unitIds }) => {
        return {
          url: '/retrigger',
          method: 'POST',
          body: {
            type,
            unitIds
          }
        }
      }
    })
  })
})

export const { useRetriggerUnitEventsMutation } = unitApi
