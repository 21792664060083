import { type FilterFn, type Row } from '@tanstack/react-table'

// Custom filter for tri-state checkbox, showing all (''), true, or false
export const booleanFilterFn: FilterFn<any> = (row: Row<any>, columnId: string, filterValue: string) => {
  const rowValue = row.getValue(columnId)
  if (filterValue === '') {
    return true
  }
  return String(rowValue) === filterValue
}

export const dateFilterFn: FilterFn<any> = (row: Row<any>, columnId: string, filterValue: string) => {
  if (!filterValue) return true
  const rowValue = row.original[columnId]
  if (!rowValue) return false
  const dateFilterValue = new Date(filterValue)
  const dateValue = new Date(rowValue)
  return dateValue.toDateString().includes(dateFilterValue.toDateString())
}
