import { mainAdminSplitApi } from 'redux/store/mainSplitApi'
import { getSimpleGrade, type GoodsHolder, type GoodsHolderDetails, type QueryParameters, type QueryResult } from 'types'

const goodsHolderApi = mainAdminSplitApi.injectEndpoints({
  endpoints: (builder) => ({
    // Partial allows the query parameters to be optional, in which case they
    // are omitted from the request URI (which the backend knows how to handle)
    searchGoodsHolder: builder.query<QueryResult<GoodsHolder>, Partial<QueryParameters<GoodsHolder>>>({
      query: (parameters) => {
        return {
          url: '/goodsholders',
          params: parameters
        }
      },
      providesTags: ['SearchGoodsHolders']
    }),
    getGoodsHolder: builder.query<GoodsHolderDetails, string>({
      query: (goodsHolderNumber) => {
        return {
          url: `/goodsholders/${goodsHolderNumber}`
        }
      },
      transformResponse (response: GoodsHolderDetails): GoodsHolderDetails {
        return {
          ...response,
          units: response.units.map((unit) => {
            return {
              ...unit,
              simpleGrade: getSimpleGrade(unit.grade)
            }
          })
        }
      }
    })
  })
})

export const { useSearchGoodsHolderQuery, useGetGoodsHolderQuery } = goodsHolderApi
