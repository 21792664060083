import { type PaginationState, type ColumnFiltersState, type SortingState } from '@tanstack/react-table'
import { type Dispatch, type SetStateAction, useState } from 'react'

export interface TableState {
  pagination: PaginationState
  setPagination: Dispatch<SetStateAction<PaginationState>>
  columnFilters: ColumnFiltersState
  setColumnFilters: Dispatch<SetStateAction<ColumnFiltersState>>
  sorting: SortingState
  setSorting: Dispatch<SetStateAction<SortingState>>
}

export const useTableState = (): TableState => {
  const [pagination, setPagination] = useState<PaginationState>({ pageIndex: 0, pageSize: 25 })
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])
  const [sorting, setSorting] = useState<SortingState>([])

  return { pagination, setPagination, columnFilters, setColumnFilters, sorting, setSorting }
}
