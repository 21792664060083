export enum AuthRole {
  REPORT_BASIC = 'REPORT_BASIC',
  REPORT_ADVANCED = 'REPORT_ADVANCED',
  ADMIN = 'ADMIN',
  SUPPORT = 'SUPPORT'
}

const roleHierarchy: Record<AuthRole, number> = {
  [AuthRole.REPORT_BASIC]: 1,
  [AuthRole.REPORT_ADVANCED]: 2,
  [AuthRole.ADMIN]: 3,
  [AuthRole.SUPPORT]: 4
}

export const hasSufficientRole = (role: AuthRole, targetRole: AuthRole): boolean => {
  return roleHierarchy[role] !== undefined && roleHierarchy[role] >= roleHierarchy[targetRole]
}
