
import { useSearchGoodsHolderQuery } from 'api/goodsholder'
import { TableView } from 'components/tableview'
import { ColumnType } from 'components/tableview/Filter'
import { usePaginatedData } from 'components/tableview/usePaginatedData'
import { useTableState } from 'components/tableview/useTableState'
import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import type { QueryResult } from 'types'
import { type GoodsHolder, GoodsHolderStatus } from 'types/GoodsHolder'
import { getEnumValues } from 'utils'
import { renderExpectedQuantityInCell } from 'utils/expectedQuantityRendering'

export const GoodsHolderOverview = () => {
  const tableState = useTableState()
  const data = usePaginatedData<QueryResult<GoodsHolder>>(useSearchGoodsHolderQuery, tableState.pagination, tableState.columnFilters, tableState.sorting)
  const navigate = useNavigate()
  const FILTER_INPUT_DELAY_MS = 500

  const columns = useMemo(() => [
    { id: 'lpn', header: 'LPN', type: ColumnType.TEXT, columnWidth: 8 },
    { id: 'rasn', header: 'RASN', type: ColumnType.TEXT, columnWidth: 8 },
    { id: 'expectedQuantity', header: 'Expected', type: ColumnType.NUMBER, columnWidth: 6, renderFn: renderExpectedQuantityInCell },
    { id: 'actualQuantity', header: 'Handled', type: ColumnType.NUMBER, columnWidth: 6 },
    { id: 'status', header: 'Status', type: ColumnType.ENUM, enumValues: getEnumValues(GoodsHolderStatus), columnWidth: 8 },
    { id: 'handledBy', header: 'Handled By', type: ColumnType.TEXT, columnWidth: 8 },
    { id: 'lastModified', header: 'Last Modified', type: ColumnType.DATE, columnWidth: 8 }
  ], [])

  return (
    <TableView
      columns={columns}
      data={data?.results ?? []}
      rowCount={data?.totalCount ?? 0}
      textFilterDelay={FILTER_INPUT_DELAY_MS}
      tableState={tableState}
      actions={[
        {
          label: 'Go to RASN',
          icon: 'Receipt',
          onClick: (row: GoodsHolder) => { navigate(`/rasn/${row.rasn}`) }
        },
        {
          label: 'Go to Goodsholder',
          icon: 'MemberProduct',
          onClick: (row: GoodsHolder) => { navigate(`/goodsholder/${row.lpn}`) }
        }
      ]}
    />
  )
}
