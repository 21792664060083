import { Divider, Tab, TabGroup } from '@nike/eds'
import { useGetGoodsHolderQuery } from 'api/goodsholder'
import { type Breadcrumb, Breadcrumbs } from 'components/breadcrumbs'
import { TableView, type TableColumn } from 'components/tableview'
import { ColumnType } from 'components/tableview/Filter'
import { CustomError } from 'pages/error-pages'
import { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Division, type ErrorType, type RasnUnit } from 'types'
import { renderExpectedQuantity, renderExpectedQuantityInCell } from 'utils/expectedQuantityRendering'
import { getEnumValues } from 'utils/utils'

import './GoodsHolderDetails.scss'

export const GoodsHolderDetails = () => {
  const [tableColumns, setTableColumns] = useState<TableColumn[]>([])
  const [tableData, setTableData] = useState<any[]>([])
  const [customFilters, setCustomFilters] = useState<Record<string, any>>({})
  const { goodsHolderId } = useParams()
  if (!goodsHolderId) { throw new Error('GoodsHolder ID is required') }
  const [activeTab, setActiveTab] = useState('tab-units')
  const { data, isLoading, isError, error } = useGetGoodsHolderQuery(goodsHolderId)

  const skuColumns = useMemo<TableColumn[]>(() => [
    { id: 'sku', header: 'SKU', type: ColumnType.TEXT, columnWidth: 6 },
    { id: 'upc', header: 'UPC', type: ColumnType.TEXT, columnWidth: 8 },
    { id: 'division', header: 'Division', type: ColumnType.ENUM, enumValues: getEnumValues(Division), columnWidth: 8 },
    { id: 'silhouette', header: 'Silhouette', type: ColumnType.TEXT, columnWidth: 12 },
    { id: 'hashable', header: 'Hash', type: ColumnType.BOOLEAN, columnWidth: 1 },
    { id: 'expectedQuantity', header: 'Expected', type: ColumnType.NUMBER, columnWidth: 6, renderFn: renderExpectedQuantityInCell },
    { id: 'actualQuantity', header: 'Handled', type: ColumnType.NUMBER, columnWidth: 6 }
  ], [])
  const unitColumns = useMemo<TableColumn[]>(() => [
    { id: 'sku', header: 'SKU', type: ColumnType.TEXT, columnWidth: 10 },
    { id: 'upc', header: 'UPC', type: ColumnType.TEXT, columnWidth: 8 },
    { id: 'division', header: 'Division', type: ColumnType.ENUM, enumValues: getEnumValues(Division), columnWidth: 7 },
    { id: 'silhouette', header: 'Silhouette', type: ColumnType.TEXT, columnWidth: 10 },
    { id: 'hashable', header: 'Hash', type: ColumnType.BOOLEAN, columnWidth: 3 },
    { id: 'quantity', header: 'Quantity', type: ColumnType.NUMBER, columnWidth: 3 },
    { id: 'coo', header: 'CoO', type: ColumnType.TEXT, columnWidth: 5 },
    { id: 'simpleGrade', header: 'Grade', type: ColumnType.ENUM, enumValues: ['A', 'B', 'C'], columnWidth: 5 },
    { id: 'gradeReason', header: 'Reason', type: ColumnType.TEXT, columnWidth: 12 },
    { id: 'unitHandlingStation', header: 'Station', type: ColumnType.TEXT, columnWidth: 6 },
    { id: 'tote', header: 'Tote', type: ColumnType.TEXT, columnWidth: 11 },
    { id: 'receivedGoodsHolder', header: 'Goodsholder', type: ColumnType.TEXT, columnWidth: 11 },
    { id: 'handledBy', header: 'Athlete*', type: ColumnType.TEXT, columnWidth: 6 },
    { id: 'lastModified', header: 'Modified', type: ColumnType.DATE, columnWidth: 12 }
  ], [])

  useEffect(() => {
    if (!data) return
    let columns: TableColumn[] = []
    let tableData: any[] = []
    switch (activeTab) {
      case 'tab-sku':
        setCustomFilters({})
        columns = skuColumns
        tableData = data.skus
        break
      case 'tab-units':
        setCustomFilters({})
        columns = unitColumns.map(column => ({
          ...column,
          initialFilterValue: customFilters[column.id] ?? column.initialFilterValue
        }))
        tableData = data.units.map((unit: RasnUnit) => ({
          ...unit
        }))
        break
    }
    setTableColumns(columns)
    setTableData(tableData)
  }, [activeTab, data])

  if (isError) {
    const errorMessage = (error as ErrorType).data.message
    return <CustomError error={errorMessage} />
  }

  if (isLoading || !data) {
    return <div>Loading...</div>
  }

  const breadcrumbs: Breadcrumb[] = [
    { name: 'GoodsHolder', path: '/goodsholder' },
    { name: `${data.lpn}` }
  ]

  return (
    <div className="m-5">
      <div className="header">
          <Breadcrumbs crumbs={breadcrumbs} />
        <div className="header-bar">
          <p><span className="label">RLPN</span>{data.lpn || 'Unknown'}</p>
          <p><span className="label">RASN</span>{data.rasn || 'Unknown'}</p>
          <p><span className="label">Expected</span>{renderExpectedQuantity(data.expectedQuantity)}</p>
          <p><span className="label">Handled</span>{data.actualQuantity || 'Unknown'}</p>
          <p><span className="label">Status</span>{data.status || 'None'}</p>
          <p><span className="label">Last handled by</span>{data.handledBy || 'None'}</p>
          <p><span className="label">Last modified</span>{data.lastModified ? data.lastModified.toLocaleString() : 'Unknown'}</p>
        </div>
      </div>
      <TabGroup
          className={'pt-6'}
          activeId={activeTab}
          name="tab-button-group"
          onChange={e => { setActiveTab(e.target.id) }}>
        <Tab id="tab-units">Units</Tab>
        <Tab id="tab-sku">SKUs</Tab>
      </TabGroup>
      <Divider className='bg-black p-px' />
      <TableView
        columns={tableColumns}
        data={tableData}
      />
    </div>
  )
}
