import { mainAdminSplitApi } from 'redux/store/mainSplitApi'
import { type ApplicationInfo } from 'types'

const appInfoApi = mainAdminSplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getAppInfo: builder.query<ApplicationInfo, void>({
      query: () => '/info'
    })
  })
})

export const { useGetAppInfoQuery } = appInfoApi
