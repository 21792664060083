import { type ColumnFiltersState, type PaginationState, type SortingState } from '@tanstack/react-table'
import { useEffect, useState } from 'react'

interface QueryParams {
  page: number
  size: number
  sortBy?: string
  direction?: 'ASC' | 'DESC'
  [key: string]: any
}

export const usePaginatedData = <T>(queryFn: (params: QueryParams) => any, pagination: PaginationState, columnFilters: ColumnFiltersState, sorting: SortingState, extraParams: Record<string, any> = {}) => {
  const [data, setData] = useState<T | undefined>(undefined)

  const queryFilters = columnFilters.reduce((acc: Record<string, any>, filter: { id: any, value: any }) => {
    return { ...acc, [filter.id]: filter.value }
  }, {})

  const result = queryFn({
    page: pagination.pageIndex,
    size: pagination.pageSize,
    sortBy: sorting[0]?.id,
    direction: sorting[0]?.desc ? 'DESC' : 'ASC',
    ...queryFilters,
    ...extraParams
  })

  useEffect(() => {
    if (result.data) {
      setData(result.data)
    }
  }, [result.data])

  return data
}
