import { IconButton } from '@nike/eds'
import { type RowData, type Table } from '@tanstack/react-table'

interface TablePagingProps {
  table: Table<RowData>
}

export const TablePaging = ({ table }: TablePagingProps) => {
  return <div className="flex w-full justify-center items-center py-2 h-12 eds-background--secondary fixed bottom-8">
        <div className='flex mr-8 gap-2'>
            <IconButton
                label=''
                size='small'
                icon='Rewind'
                onClick={() => {
                  table.firstPage()
                }}
                disabled={!table.getCanPreviousPage()}
            />
            <IconButton
                label=''
                size='small'
                icon='CaretLeft'
                onClick={() => {
                  table.previousPage()
                }}
                disabled={!table.getCanPreviousPage()}
            />
            <IconButton
                label=''
                size='small'
                icon='CaretRight'
                onClick={() => {
                  table.nextPage()
                }}
                disabled={!table.getCanNextPage()}
            />
            <IconButton
                label=''
                size='small'
                icon='FastForward'
                onClick={() => {
                  table.lastPage()
                }}
                disabled={!table.getCanNextPage()}
            />
        </div>
        <div className='flex'>
          <span className="flex items-center gap-1 mx-2 px-2 rounded eds-background--default">
            <div>Page</div>
            <strong>
              {table.getState().pagination.pageIndex + 1} of{' '}
                {table.getPageCount().toLocaleString()}
            </strong>
          </span>
            <span className="flex items-center gap-1 mx-2 px-2 rounded eds-background--default">
            Go to page:
            <input
                type="number"
                value={table.getState().pagination.pageIndex + 1}
                onChange={e => {
                  const page = e.target.value ? Number(e.target.value) - 1 : 0
                  table.setPageIndex(page)
                }}
                className="p-1 rounded w-16"
            />
          </span>
            <select
                className='eds-type--body-2 h-8 mx-2 border eds-background--default'
                value={table.getState().pagination.pageSize}
                onChange={e => {
                  table.setPageSize(Number(e.target.value))
                }}
            >
                {[25, 50, 100].map(pageSize => (
                    <option key={pageSize} value={pageSize}>
                        Show {pageSize}
                    </option>
                ))}
            </select>
        </div>
    </div>
}
