import { NikeApp } from '@nike/nike-design-system-icons'
import './Footer.css'
import { useOktaAuth } from '@okta/okta-react'
import { useState } from 'react'

import { AppInfoModal } from '../info'

export function Footer () {
  const { authState } = useOktaAuth()
  const [showAppInfo, setShowAppInfo] = useState<boolean>(false)

  return (
    <>
    <div className="footer h-8">
        <NikeApp
            title="Nike logo"
            color="black"
            width="56px"
            height="32px"
            onClick={() => {
              setShowAppInfo(true)
            }}
        />
    </div>
    { authState?.isAuthenticated && showAppInfo &&
      <AppInfoModal setShowAppInfo={setShowAppInfo}/>
    }
  </>
  )
}
