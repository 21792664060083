import { Divider, Tab, TabGroup } from '@nike/eds'
import { type CellContext } from '@tanstack/react-table'
import { useCloseRasnMutation, useGetRasnQuery, useUnlockRasnMutation } from 'api/rasn'
import { type Breadcrumb, Breadcrumbs } from 'components/breadcrumbs'
import { ConfirmationModal } from 'components/confirmation-modal'
import { CloseRasnIcon } from 'components/icon'
import { TableView, type TableColumn } from 'components/tableview'
import { ColumnType } from 'components/tableview/Filter'
import { CustomError } from 'pages/error-pages'
import { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Division, type ErrorType, GoodsholderStatus, type RasnGoodsholder, RasnStatus } from 'types'
import { renderExpectedQuantity, renderExpectedQuantityInCell } from 'utils/expectedQuantityRendering'
import { getEnumValues } from 'utils/utils'

import './RasnDetailsView.scss'

export const RasnDetails = () => {
  const [tableColumns, setTableColumns] = useState<TableColumn[]>([])
  const [tableData, setTableData] = useState<any[]>([])
  const [customFilters, setCustomFilters] = useState<Record<string, any>>({})
  const { rasnId } = useParams()
  if (!rasnId) { throw new Error('RASN ID is required') }
  const [activeTab, setActiveTab] = useState('tab-units')
  const { data, isLoading, isError, error } = useGetRasnQuery(rasnId)
  const [unlockRasn] = useUnlockRasnMutation()
  const [closeRasn] = useCloseRasnMutation()

  const renderNullableSkuField = (context: CellContext<any, any>) => {
    const { row, column } = context

    if (row.original.resolved) {
      return <div>{row.getValue(column.id)}</div>
    } else {
      return <div
        title='This SKU is present in the RASN, but U90 was unable to map it to known data'
        className='eds-color--text-danger'
      >
        ⚠️ missing data
      </div>
    }
  }

  const skuColumns = useMemo<TableColumn[]>(() => [
    { id: 'sku', header: 'SKU', type: ColumnType.TEXT, columnWidth: 6 },
    { id: 'upc', header: 'UPC', type: ColumnType.TEXT, columnWidth: 8, renderFn: renderNullableSkuField },
    { id: 'division', header: 'Division', type: ColumnType.ENUM, enumValues: getEnumValues(Division), columnWidth: 8, renderFn: renderNullableSkuField },
    { id: 'silhouette', header: 'Silhouette', type: ColumnType.TEXT, columnWidth: 12, renderFn: renderNullableSkuField },
    { id: 'hashable', header: 'Hash', type: ColumnType.BOOLEAN, columnWidth: 1 },
    { id: 'expectedQuantity', header: 'Expected', type: ColumnType.NUMBER, columnWidth: 6, renderFn: renderExpectedQuantityInCell },
    { id: 'actualQuantity', header: 'Handled', type: ColumnType.NUMBER, columnWidth: 6 }
  ], [])
  const unitColumns = useMemo<TableColumn[]>(() => [
    { id: 'sku', header: 'SKU', type: ColumnType.TEXT, columnWidth: 10 },
    { id: 'upc', header: 'UPC', type: ColumnType.TEXT, columnWidth: 8 },
    { id: 'division', header: 'Division', type: ColumnType.ENUM, enumValues: getEnumValues(Division), columnWidth: 7 },
    { id: 'silhouette', header: 'Silhouette', type: ColumnType.TEXT, columnWidth: 10 },
    { id: 'hashable', header: 'Hash', type: ColumnType.BOOLEAN, columnWidth: 3 },
    { id: 'quantity', header: 'Quantity', type: ColumnType.NUMBER, columnWidth: 3 },
    { id: 'coo', header: 'CoO', type: ColumnType.TEXT, columnWidth: 5 },
    { id: 'simpleGrade', header: 'Grade', type: ColumnType.ENUM, enumValues: ['A', 'B', 'C'], columnWidth: 5 },
    { id: 'gradeReason', header: 'Reason', type: ColumnType.TEXT, columnWidth: 12 },
    { id: 'unitHandlingStation', header: 'Station', type: ColumnType.TEXT, columnWidth: 6 },
    { id: 'tote', header: 'Tote', type: ColumnType.TEXT, columnWidth: 11 },
    { id: 'receivedGoodsHolder', header: 'Goodsholder', type: ColumnType.TEXT, columnWidth: 11 },
    { id: 'handledBy', header: 'Athlete*', type: ColumnType.TEXT, columnWidth: 6 },
    { id: 'lastModified', header: 'Modified', type: ColumnType.DATE, columnWidth: 12 }
  ], [])
  const goodsholderColumns = useMemo<TableColumn[]>(() => [
    { id: 'lpn', header: 'Goodsholder LPN', type: ColumnType.TEXT },
    { id: 'expectedQuantity', header: 'Expected', type: ColumnType.NUMBER, renderFn: renderExpectedQuantityInCell },
    { id: 'actualQuantity', header: 'Handled', type: ColumnType.NUMBER },
    { id: 'status', header: 'Status', type: ColumnType.ENUM, enumValues: getEnumValues(GoodsholderStatus) },
    { id: 'handledBy', header: 'Last modified by', type: ColumnType.TEXT },
    { id: 'lastModified', header: 'Last modified time', type: ColumnType.DATE }
  ], [])

  const canUseRasnBookkeeping = localStorage.getItem('rasn_bookkeeping') === 'true'

  useEffect(() => {
    if (!data) return
    let columns: TableColumn[] = []
    let tableData: any[] = []
    switch (activeTab) {
      case 'tab-sku':
        setCustomFilters({})
        columns = skuColumns
        tableData = data.skus
        break
      case 'tab-units':
        setCustomFilters({})
        columns = unitColumns.map(column => ({
          ...column,
          initialFilterValue: customFilters[column.id] ?? column.initialFilterValue
        }))
        tableData = data.units.map(unit => ({
          ...unit
        }))
        break
      case 'tab-goodsholders':
        columns = goodsholderColumns
        tableData = data.goodsholders
        break
    }
    setTableColumns(columns)
    setTableData(tableData)
  }, [activeTab, data])

  const goToUnitsAndFilterAutomaticallyOnGoodsholder = (goodsholder: string) => {
    setCustomFilters({ receivedGoodsHolder: goodsholder })
    setActiveTab('tab-units')
  }

  if (isError) {
    const errorMessage = (error as ErrorType).data.message
    return <CustomError error={errorMessage} />
  }

  if (isLoading || !data) {
    return <div>Loading...</div>
  }

  const breadcrumbs: Breadcrumb[] = [
    { name: 'RASN', path: '/' },
    { name: `${data.number}` }
  ]

  return (
    <div className="m-5">
      <div className="header">
          <Breadcrumbs crumbs={breadcrumbs} />
          <div className="header-bar">
              <p><span className="label">Customer</span>{data.customerName || 'Unknown'}</p>
              <p><span className="label">Channel</span>{data.channel || 'Unknown'}</p>
              <p><span className="label">Status</span>{data.status || 'Unknown'}</p>
              <p><span className="label">Hold code</span>{data.holdCode || 'None'}</p>
              <p><span className="label">Expected</span>{data.skus ? renderExpectedQuantity(data.skus.map(s => s.expectedQuantity).reduce((acc, qty) => acc + qty, 0)) : 'Unknown'}</p>
              <p><span className="label">Handled</span>{data.units ? data.units.map(u => u.quantity).reduce((acc, qty) => acc + qty, 0) : 'Unknown'}</p>
          </div>
          {data.holdCode && (
            <>
              <ConfirmationModal
                onTrigger={() => { unlockRasn(rasnId) }}
                title='Unlock RASN'
                message="Are you sure you want to unlock this RASN?"
                icon='Unlock'
                iconClass='float-right'
                confirmText='Unlock'
              />
            </>
          )}
          {canUseRasnBookkeeping && data.status !== RasnStatus.CLOSED && (
            <>
              <ConfirmationModal
                onTrigger={() => { closeRasn(rasnId) }}
                title='Close RASN'
                message="Are you sure you want to close this RASN?"
                icon={CloseRasnIcon}
                iconClass='float-right'
                confirmText='Close'
              />
            </>
          )}
      </div>
      <TabGroup
          className={'pt-6'}
          activeId={activeTab}
          name="tab-button-group"
          onChange={e => { setActiveTab(e.target.id) }}>
        <Tab id="tab-units">Units</Tab>
        <Tab id="tab-sku">SKUs</Tab>
        <Tab id="tab-goodsholders">GoodsHolders</Tab>
      </TabGroup>
      <Divider className='bg-black p-px' />
      <TableView
        columns={tableColumns}
        data={tableData}
        actions={activeTab === 'tab-goodsholders' ? [{ label: 'View', icon: 'Zoomin', onClick: (row: RasnGoodsholder) => { goToUnitsAndFilterAutomaticallyOnGoodsholder(row.lpn) } }] : []}
      />
    </div>
  )
}
