import { type CellContext } from '@tanstack/react-table'

export const renderExpectedQuantity = (expectedQuantity: number) => {
  return expectedQuantity === 0
    ? <div
        title = 'At the time this RASN/RLPN was created, U90 did not record the expected content'
        className = 'eds-color--yellow-60'
      >
        ⚠️ unknown
      </div>
    : <div>{ expectedQuantity }</div>
}

export const renderExpectedQuantityInCell = (context: CellContext<any, any>) =>
  renderExpectedQuantity(context.row.getValue(context.column.id))
