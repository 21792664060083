import '@nike/eds/dist/index.css'
import OktaAuth from '@okta/okta-auth-js'
import { Security } from '@okta/okta-react'
import AuthConfig from 'auth/AuthConfig'
import { Footer } from 'components/footer'
import { Header } from 'components/header'
import { SnackBar } from 'components/snackbar'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Routes } from 'router/Routes'

function App () {
  const clientAuth = new OktaAuth(AuthConfig.oidc)
  const navigate = useNavigate()

  clientAuth.start().catch(error => {
    console.error(error)
  })

  const restoreOriginalUri = async (_oktaAuth: any, originalUri: string) => {
    navigate('/')
  }

  useEffect(() => {
    return () => {
      clientAuth.options.restoreOriginalUri = undefined
    }
  }, [])

  return (
    <Security
      oktaAuth={clientAuth}
      restoreOriginalUri={restoreOriginalUri}>
      <Header />
      <SnackBar />
      <Routes />
      <Footer />
    </Security>
  )
}

export default App
