import React from 'react'
import { Link } from 'react-router-dom'
import './Breadcrumbs.scss'
export interface BreadcrumbProps {
  crumbs: Breadcrumb[]
}

export interface Breadcrumb {
  name: string
  path?: string
}

export const Breadcrumbs = ({ crumbs }: BreadcrumbProps) => {
  return (
    <nav className='breadcrumbs'>
      {crumbs.map((crumb, index) => (
        <span key={index} className="breadcrumb">
          {crumb.path
            ? (<>
              <Link to={crumb.path}>{crumb.name}</Link>
              {index < crumbs.length - 1 && ' / '}
            </>)
            : <span>{crumb.name}</span>}
        </span>
      ))}
    </nav>
  )
}
