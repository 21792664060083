import { Icon, IconButton, TextField } from '@nike/eds'
import { type Column } from '@tanstack/react-table'
import { useEffect, useState, useCallback, type MouseEvent } from 'react'

import { TristateCheckbox } from '../tristate-checkbox'

export enum ColumnType {
  ENUM,
  TEXT,
  NUMBER,
  DATE,
  BOOLEAN
}

export interface FilterProps {
  column: Column<any, any>
  type: ColumnType
  textFilterDelay?: number
  enumValues?: string[]
  initialValue?: any
}

const useDebouncedValue = (value: string, delay: number) => {
  const [debouncedValue, setDebouncedValue] = useState(value)

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value)
    }, delay)

    return () => {
      clearTimeout(handler)
    }
  }, [value, delay])

  return debouncedValue
}

export const Filter = ({ column, type, textFilterDelay, enumValues: values, initialValue }: FilterProps) => {
  const [inputValue, setInputValue] = useState<string>('')
  const [isFilterVisible, setIsFilterVisible] = useState<boolean>(false)
  const debouncedValue = useDebouncedValue(inputValue, textFilterDelay ?? 1000)
  useEffect(() => {
    if (initialValue !== undefined) {
      setIsFilterVisible(true)
      setInputValue(initialValue)
    }
  }, [initialValue])

  // useCallback required as setFilterValue is a dependency for useEffect, preventing infinite loop and re-render each time the component re-renders
  const setFilterValue = useCallback((value: string) => {
    const currentFilterValue = column.getFilterValue()
    if ((currentFilterValue === undefined && value !== '') ||
        (currentFilterValue !== value && value !== '') ||
        (currentFilterValue !== undefined && value === '')) {
      column.setFilterValue(value)
    }
  }, [column])

  useEffect(() => {
    setFilterValue(debouncedValue)
  }, [debouncedValue, setFilterValue])

  const setFilterVisibility = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    setIsFilterVisible(prevState => !prevState)
  }

  return (
      <div>
        {!isFilterVisible
          ? <IconButton
            icon={<Icon name="Search" />}
            variant={'ghost'}
            onClick={setFilterVisibility}
            className="absolute right-0 top-0"
            label={'Filter'}
          />
          : (
            <>
              {type === ColumnType.ENUM && (
                  <select
                      className='w-full mt-2 eds-type--body-2 h-8 border rounded !eds-border--disabled'
                      onChange={e => { setInputValue(e.target.value) }}
                      onClick={e => { e.stopPropagation() }}
                      value={inputValue}
                   >
                    <option value=''>All</option>
                    {values?.map(option => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                    ))}
                  </select>
              )}
              {(type === ColumnType.TEXT || type === ColumnType.NUMBER) && (
                  <TextField
                      id=''
                      label=''
                      hideLabel={true}
                      className='h-8 mt-2 !eds-border--disabled'
                      onChange={e => { setInputValue(e.target.value) }}
                      onClick={e => { e.stopPropagation() }}
                      placeholder={''}
                      value={inputValue}
                  />
              )}
              {type === ColumnType.DATE && (
                  <div className="flex flex-col mt-2">
                    <input
                        type="date"
                        className="eds-type--body-2 h-8 border rounded !eds-border--disabled text-gray-500"
                        onChange={e => { setInputValue(e.target.value) }}
                        onClick={e => { e.stopPropagation() }}
                    />
                  </div>
              )}
              {type === ColumnType.BOOLEAN && (
                  <label className="mt-3 flex items-center justify-center cursor-pointer">
                    <TristateCheckbox checked={inputValue} setChecked={setInputValue} />
                  </label>
              )}
            </>
            )}
      </div>
  )
}
